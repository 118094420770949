import { mapState,  mapActions } from 'vuex'

// WRITTING MY OWN CUSTOM METTODS


//AUTHENTICATION MAP STATE
//THIS WILL BE AVAILABLE ANY WHERE IN THE APP
export const authComputed = {
  ...mapState('auth', {
    currentUser: state => state.currentUser,
    entities: state => state.entities,
  })
}

export const layoutComputed = {
  ...mapState('layout', {
    layoutType: (state) => state.layoutType,
    leftSidebarType: (state) => state.leftSidebarType,
    layoutWidth: (state) => state.layoutWidth,
    topbar: (state) => state.topbar,
    loader: (state) => state.loader
  })
}


// AUTHENTICATION MAGE ACTIONS 
export const authMethods = mapActions('auth',['getUserDetails','validateServiceTicket'])

export const layoutMethods = mapActions('layout', ['changeLayoutType', 'changeLayoutWidth', 'changeLeftSidebarType', 'changeTopbar', 'changeLoaderValue'])

export const notificationMethods = mapActions('notification', ['success', 'error', 'clear'])
