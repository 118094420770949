<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 justify-content-center">
            <p class="text-center">Copyright © {{fullYear}} 4D Green Corridor</p>
            <!-- <p class="text-center">In association with the African Union Open Corridors Initiative</p>
            <p class="text-center">In partnership with the AfCFTA Secretariat</p> -->
        </div>
      </div>
    </div>
  </footer>
</template>
<style scoped>
footer {
  margin-top: 200px!important;
}
</style>
<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  computed:{
    fullYear(){
      return new Date().getFullYear()
    }
  }
})
</script>